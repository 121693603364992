import React from 'react';
import {ReactSocialMediaIcons} from 'react-social-media-icons';
import { Card, Row, Col, Container, CardBody } from 'reactstrap';

const styles = {
    height: "100vh",
}

export default function Links() {
    return (
        <div>
            <Container fluid className="bg-dark text-white" style={styles}>
                <Row className="justify-content-md-center">
                    <Col sm="2" className="py-5">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <ReactSocialMediaIcons borderColor="rgba(0,0,0,0.25)" icon="linkedin" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(0,119,181,1)" url="https://www.linkedin.com/in/callum-mcneilage" size="100" />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="2" className="py-5">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <ReactSocialMediaIcons borderColor="rgba(0,0,0,0.25)" icon="facebook" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(59,89,152,1)" url="https://www.facebook.com/profile.php?id=100013636321927" size="100" />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="2" className="py-5">
                        <Card>
                            <CardBody className="bg-dark text-white">
                            <ReactSocialMediaIcons borderColor="rgba(0,0,0,0.25)" icon="youtube" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(196,48,43,1)" url="https://www.youtube.com/channel/UCSpmBSbgNGp5qkILMxg29uA" size="100" />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="2" className="py-5">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <ReactSocialMediaIcons borderColor="rgba(0,0,0,0.25)" icon="github" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(33,31,31,1)" url="https://github.com/Sunthas515" size="100" />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="2" className="py-5">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <ReactSocialMediaIcons borderColor="rgba(0,0,0,0.25)" icon="stackoverflow" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(244,128,36,1)" url="https://stackoverflow.com/users/11556343/callum-mcneilage" size="100" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}