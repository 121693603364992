import React from 'react';
import image from './download.png';

export default function Image() {
    return (
    <div className="Image">
        <h1>
            <img src={image} className="Site-image" alt="" />
        </h1>
    </div>
    )
}