import React from 'react';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, CardLink, Row, Col, Container } from 'reactstrap';

import IFB102 from '../Work_Images/IFB102.jpg'
import IFB103 from '../Work_Images/IFB103.png'
import IFB104 from '../Work_Images/IFB104.png'
import IFB105 from '../Work_Images/IFB105.png'
import IGB180 from '../Work_Images/IGB180.png'
import CAB201 from '../Work_Images/CAB201.png'
import CAB202 from '../Work_Images/CAB202.png'
import CAB240 from '../Work_Images/CAB240.png'
import MXB103 from '../Work_Images/MXB103.png'
import CAB230 from '../Work_Images/CAB230.jpg'
import CAB302 from '../Work_Images/CAB302.png'
import CAB320 from '../Work_Images/CAB320.png'
import CAB303 from '../Work_Images/CAB303.png'
import CAB340 from '../Work_Images/CAB340.png'
import EGB339 from '../Work_Images/EGB339.png'
import IFB295 from '../Work_Images/IFB295.png'
import CAB301 from '../Work_Images/CAB301.png'
import CAB420 from '../Work_Images/CAB420.png'
import Capstone from '../Work_Images/Capstone.png'
import DOWAM from '../Work_Images/dowam.png'

export default function PreviousWork() {
    return (
        <div>
            <Container fluid className="bg-dark text-white">
                <h1>Previous Work</h1>
                <Row>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <CardTitle>This Site</CardTitle>
                                <CardText>This site was built using JavaScript React and is hosted on Netlify.</CardText>
                                <CardText>Layout components are using reactstrap and social media icons are using social-media-icons-react</CardText>
                                <CardLink href="https://www.npmjs.com/package/reactstrap">reactstrap</CardLink>
                                <CardLink href="https://www.npmjs.com/package/social-media-icons-react">social-media-icons-react</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <CardTitle>Swift Projects</CardTitle>
                                <CardSubtitle>Swift projects undertaken in own time to learn SwiftUI programming</CardSubtitle>
                                <CardLink href="https://github.com/Sunthas515/Swift-Projects">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={DOWAM} alt="DOWAM" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>Dungeons of Wrath and Mana</CardTitle>
                                <CardSubtitle>Game made in UE4 for the Australian STEM Games Competition.</CardSubtitle>
                                <CardText>Was a project between me and two friends during Grade 12. All content including sprites, sound effects and music are all original content.</CardText>
                                <CardText>Competition finalist with both judges commending us for our achievement</CardText>
                                <CardLink href="https://maldonacho.itch.io/dowam">Itch.IO</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={IFB102} alt="IFB102 Application Screenshot" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IFB102</CardTitle>
                                <CardSubtitle>Introduction to Computer Systems</CardSubtitle>
                                <CardText>Final Assessment: Raspberry Pi Project</CardText>
                                <CardLink href="https://github.com/Sunthas515/102-raspberry-pi-project">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={IFB103} alt="IFB103 Report Image" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IFB103</CardTitle>
                                <CardSubtitle>IT Systems Design</CardSubtitle>
                                <CardText>Logbook is a UX design challenge tasked with creating an application using UX design principles. Assessment 3 is UML Diagrams and a short response evaluating the impact of Amazon.com.au</CardText>
                                <CardText>The logbook assignment was a group assignment, however, we were all involved in all stages of the assignment. Assessment 3 was individual.</CardText>
                                <CardLink href="https://github.com/Sunthas515/103-app-UX-design">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={IFB104} alt="IFB104 Projects" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IFB104</CardTitle>
                                <CardSubtitle>Building IT Systems</CardSubtitle>
                                <CardText>Python turtle and web scripting projects</CardText>
                                <CardLink href="https://github.com/Sunthas515/104-Python">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={IFB105} alt="IFB105 ORM/Database" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IFB105</CardTitle>
                                <CardSubtitle>Database Management</CardSubtitle>
                                <CardText>ORM Schemas and database management using MySQL Workbench</CardText>
                                <CardLink href="https://github.com/Sunthas515/105-Databases">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={IGB180} alt="IGB180 report and vid" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IGB180</CardTitle>
                                <CardSubtitle>Computer Games Studies</CardSubtitle>
                                <CardText>Report on Microtransactions in the Video Game Industry and video essay on the Social Impacts of ESports</CardText>
                                <CardLink href="https://www.linkedin.com/pulse/microtransactions-really-bad-thing-callum-mcneilage?articleId=6537905677237612544#comments-6537905677237612544&trk=public_profile_article_view">Report</CardLink>
                                <CardLink href="https://www.youtube.com/watch?v=x1C7wYBeH8g">Video</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB201} alt="CAB201 sequences" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB201</CardTitle>
                                <CardSubtitle>Programming Principles</CardSubtitle>
                                <CardText>Console-based string parser using C#</CardText>
                                <CardLink href="https://github.com/Sunthas515/201-Sequencer">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB202} alt="CAB202 games" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB202</CardTitle>
                                <CardSubtitle>Microprocessors and Digital Systems</CardSubtitle>
                                <CardText>Console Games written for both Unix terminal and Teensy MicroController using C</CardText>
                                <CardLink href="https://github.com/Sunthas515/202-Games">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB240} alt="CAB240 report" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB240</CardTitle>
                                <CardSubtitle>Information Security</CardSubtitle>
                                <CardText>Simulated Security Report based on personal phone usage</CardText>
                                <CardLink href="https://www.linkedin.com/pulse/mobile-phone-security-investigation-callum-mcneilage?articleId=6684294422177423360#comments-6684294422177423360&trk=public_profile_article_view">Report</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={MXB103} alt="MXB103 Report" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>MXB103</CardTitle>
                                <CardSubtitle>Introductory Computational Mathematics</CardSubtitle>
                                <CardText>Mathematical Report using MATLAB for fictional client case</CardText>
                                <CardText>As this was a group assignment and a subject that was part of the Mathematics degree, I mainly worked on the actual MATLAB code while majority of the logic and working out was the other group members who were Mathematics majors.</CardText>
                                <CardLink href="https://github.com/Sunthas515/Group-13">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB230} alt="CAB230 Website" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB230</CardTitle>
                                <CardSubtitle>Web Computing</CardSubtitle>
                                <CardText>Web API and Application for displaying stockmarket data using Node.js and React</CardText>
                                <CardLink href="https://github.com/Sunthas515/230-Web-App">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB302} alt="CAB302 Application" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB302</CardTitle>
                                <CardSubtitle>Software Development</CardSubtitle>
                                <CardText>Network Application for displaying billboards in a workplace using Java</CardText>
                                <CardText>This was a group Assignment. I primarily worked on the front-end and user interface for the Control Panel application, as well as the password and other encryption. Closer to the due date I also helped out with debugging and network tasks to ensure we had a working product for hand in.</CardText>
                                <CardLink href="https://github.com/Sunthas515/302-major-project">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB320} alt="CAB320 AI" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB320</CardTitle>
                                <CardSubtitle>Artifical Intelligence</CardSubtitle>
                                <CardText>Small Sokoban puzzle solver and Machine Learning scripts using Python</CardText>
                                <CardText>These were both group assignments. For the Sokoban Solver, I worked primarily on the taboo_cells function and helped out elsewhere where I could. For the Machine Learning assignment, I completed all functions except the Neural Network classifier function</CardText>
                                <CardLink href="https://github.com/Sunthas515/320-sokoban">Sokoban Solver</CardLink>
                                <CardLink href="https://github.com/Sunthas515/cab320-ML">Machine Learning</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB303} alt="CAB303 Networks" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB303</CardTitle>
                                <CardSubtitle>Networks</CardSubtitle>
                                <CardText>Report detailing ways to fix the problem of users not being able to access a website due to SYN Flood attack</CardText>
                                <CardText>This was a group assignment with us all contributing to all facets of the task.</CardText>
                                <CardLink href="https://www.linkedin.com/posts/callum-mcneilage_network-maintenance-task-activity-6737571153696714753-RTIo">Report</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB340} alt="CAB340 Crypto" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB340</CardTitle>
                                <CardSubtitle>Cryptography</CardSubtitle>
                                <CardText>Three reports of varying tasks from different areas of Cryptography: Historical Ciphers, Symmetric Ciphers and Asymmetric Ciphers</CardText>
                                <CardLink href="https://github.com/Sunthas515/340-Crypto">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={EGB339} alt="EGB339 Robotics" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>EGB339</CardTitle>
                                <CardSubtitle>Introduction to Robotics</CardSubtitle>
                                <CardText>MATLAB Tasks around computer vision and robot kinematics</CardText>
                                <CardText>Combination of Individual and Group tasks - See Readme for more detailed information</CardText>
                                <CardLink href="https://github.com/Sunthas515/339-Vision-tasks">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={IFB295} alt="IFB295 Project Management" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IFB295</CardTitle>
                                <CardSubtitle>IT Project Management</CardSubtitle>
                                <CardText>Two reports based on Agile Development.</CardText>
                                <CardText>This was a group assignment with us all contributing to all facets of the task.</CardText>
                                <CardLink href="https://www.linkedin.com/posts/callum-mcneilage_release-and-sprint-plan-activity-6737574214091591680-xSDg">Release and Sprint Plan</CardLink>
                                <CardLink href="https://www.linkedin.com/posts/callum-mcneilage_project-plan-activity-6737574098861486080-KI9A">Project Plan</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB301} alt="CAB301 Algorithms and Complexity" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB301</CardTitle>
                                <CardSubtitle>Algorithms and Complexity</CardSubtitle>
                                <CardText>Individual Assignment to create a management system for a Tool Library</CardText>
                                <CardText>This task is not indicative of my programming ability due to tight and constantly changing restrictions placed on what we were allowed to do throughout the project.</CardText>
                                <CardLink href="https://github.com/Sunthas515/301-tool-library">GitHub</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={CAB420} alt="CAB420 Machine Learning" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB420</CardTitle>
                                <CardSubtitle>Machine Learning</CardSubtitle>
                                <CardText>Ten tasks split over two group assignments</CardText>
                                <CardText>Assignment 1 was a partner assignment and Assignment 2 was completed in a Group of 3</CardText>
                                <CardLink href="https://github.com/Sunthas515/420-1">Assignment 1</CardLink>
                                <CardLink href="https://github.com/Sunthas515/420-2">Assignment 2</CardLink>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <CardTitle>CAB440</CardTitle>
                                <CardSubtitle>Network and Systems Administration</CardSubtitle>
                                <CardText>All assessment completed in exam conditions, as such, no assessment is able to be published</CardText>
                                <CardText>Course content included CISCO Routing and basic System Administration tasks in Ubuntu 18.04</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="3" className="py-3">
                        <Card>
                            <CardImg src={Capstone} alt="IFB398-IFB399 Capstone" />
                            <CardBody className="bg-dark text-white">
                                <CardTitle>IFB398/IFB399</CardTitle>
                                <CardSubtitle>Capstone</CardSubtitle>
                                <CardText>Capstone task for CSIRO: Data61</CardText>
                                <CardText>Project being completed as a group of 4 - Business Process Compliance Tool</CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}