import React from 'react';
import { Card, Row, Col, Container, NavLink, CardBody } from 'reactstrap';

export default function Education() {
    return (
        <div>
            <Container fluid className="bg-dark text-white">
                <Row>
                    <Col sm="6">
                        <h2>Awards</h2>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2015</h3>
                                <h4>Academic Excellence Medallion: The Gap State High School</h4>
                                <p>Awarded for achieving A result in all subjects</p>
                                <h4>Citizenship Award: The Gap State High School</h4>
                                <p>Awarded for contribution to The Gap State High School Community</p>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2016</h3>
                                <h4>Academic Excelence Medallion: The Gap State High School</h4>
                                <p>Awarded for achieving A result in all subjects</p>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2017</h3>
                                <h4>Cultral Award: The Gap State High School</h4>
                                <p>Awarded for contribution to The Gap State High School Arts Program</p>
                                <h4>Citizenship Award: The Gap State High School</h4>
                                <p>Awarded for contribution to The Gap State High School Community</p>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2018</h3>
                                <h4>Academic Excelence Medallion: The Gap State High School</h4>
                                <p>Awarded for achieving A result in all subjects</p>
                                <h4>Finalist: Australian STEM Games Challenge</h4>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <h2>Education</h2>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2013-2018</h3>
                                <h4>The Gap State High School</h4>
                                <ul>Senior Study:
                                    <li>English</li>
                                    <li>Maths B</li>
                                    <li>Chemistry</li>
                                    <li>Information Technology</li>
                                    <li>Music</li>
                                    <li>Chinese (Mandarin)</li>
                                </ul>
                                <h5>Result: OP4</h5>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2019-2021</h3>
                                <h4>Queensland University of Technology</h4>
                                <ul>2019: Semester 1
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB102">IFB102 Introduction to Computer Systems: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB103">IFB103 IT Systems Design: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB104">IFB104 Building IT Systems: 7</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB105">IFB105 Database Management: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IGB180">IGB180 Computer Games Studies: 6</NavLink>
                                </ul>
                                <ul>2019: Semester 2
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB201">CAB201 Programming Principles: 4</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB202">CAB202 Microprocessors and Digital Systems: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB240">CAB240 Information Security: 6</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=MXB103">MXB103 Introductory Computational Mathematics: 5</NavLink>
                                </ul>
                                <ul>2020: Semester 1
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB203">CAB203 Discrete Structres: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB230">CAB230 Web Computing: 7</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB302">CAB302 Software Development: 6</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB320">CAB320 Artificial Intelligence: 4</NavLink>
                                </ul>
                                <ul>2020: Semester 2
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB303">CAB303 Networks: 6</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB340">CAB340 Cryptography: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=EGB339">EGB339 Introduction to Robotics: 5</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB295">IFB295 IT Project Management: 6</NavLink>
                                </ul>
                                <ul>2021: Semester 1
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB398">IFB398 Capstone Project (Phase 1): Awaiting Result</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB301">CAB301 Algorithms and Complexity: Awaiting Result</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB420">CAB420 Machine Learning: Awaiting Result</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB440">CAB440 Network and Systems Administration: Awaiting Result</NavLink>
                                </ul>
                                <ul>2021: Semester 2
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=IFB399">IFB399 Capstone Project (Phase 2): Future Enrolment</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB403">CAB403 Systems Programming: Future Enrolment</NavLink>
                                    <NavLink href="https://www.qut.edu.au/study/unit?unitCode=CAB441">CAB441 Network Security: Future Enrolment</NavLink>
                                </ul>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody className="bg-dark text-white">
                                <h3>2019-2021</h3>
                                <h4>Certificate III Retail Services</h4>
                                <p>Completed as part of McDonald's Leadership Program</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}