import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Image from "./Components/Image"
import Hero from "./Components/Hero";
import Home from "./Components/Home";
import PreviousWork from "./Components/Previous-work";
import Education from "./Components/Education";
import Links from "./Components/Links";

function App() {
  return (
    <div className="App">
      <Router>
        <Image />
        <Hero />
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/previous-work" element={<PreviousWork/>} />
          <Route exact path="/education" element={<Education/>} />
          <Route exact path="/links" element={<Links/>} />
        </Routes>
      </Router>
    </div>
  )
}

export default App;
