import React from 'react';
import { Container } from 'reactstrap';

const styles = {
    height: "100vh",
}

export default function Education() {
    return (
        <div class="d-flex align-content-stretch flex-wrap">
            <Container fluid className="bg-dark text-white" style={styles}>
                <h1>A little about me</h1>
                <p>I am currently employed as a Cyber Technologist with the Australian Government.</p>
                <p>At university, I was a member of the QUT CODE Network and Game Development Club and I tried to attend these as often as possible to meet new people and further my programming skills in varying contexts, environments and Operating Systems. I have experience with Windows, Mac and Ubuntu-based Linux operating systems.</p>
                <p>In my free time, I enjoy traveling with my friends and family, building small programs to help myself and others speed up study and tasks, playing games with friends, and playing music (guitar/trumpet).</p>
                <p>In 2016, I was selected to be a part of the first Science Technology Engineering & Maths (STEM) scholarship study tour to Taiwan, where I studied at a local school for two weeks as a complete Mandarin Chinese immersion with 10 other students from around the South East Queensland. Because of this immersion, I was able to develop my communication skills and problem-solving skills during conversations with my homestay, who spoke very little English. During my time in Taipei, I was able to see how the Technology Industry operates through trips inside places such as MSI and gained a rich understanding of Taiwan's and China's history.</p>
                <p>My role as Music Captain, in High School, allowed me to improve both my planning and time management skills. As well, this role has given me the opportunity to mentor and help improve younger students' abilities with their instruments, while giving me many volunteer roles in both catering and technical production for a range of school and community events. In addition, I received many academic excellence awards throughout my time in High School.</p>
                <p>As a crew member at McDonald's, I have primarily worked in the customer services area. Taking orders and handling money is a big part of this as well as engaging with customers and having genuine conversations with customers outside of the "What can I get you today?". This has allowed me to further improve my people skills and communication skills. In addition to this, my role has also included helping less experienced crew and crew in areas of the store that may be busier than mine in order to meet store goals and expectations.</p>
            </Container>
        </div>
    )
}