import React from 'react';

import Toolbar from "./toolbar"

export default function Hero() {
    return (
        <div className="hero-image">
            <div className="hero-text">
                <h1>Callum McNeilage</h1>
                <p>I'm a Cyber Security Technologist specialising in ICS</p>
                <Toolbar />
            </div>
        </div>
    )
}